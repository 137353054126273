define("shared/components/form-members/component", ["exports", "shared/components/form-members/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    errors: null,
    memberArray: null,
    memberConfig: null,
    model: null,
    primaryResource: null,
    roles: null,
    type: null,
    users: null,
    creator: null,
    showCreator: true,
    toAddCustom: null,
    _bindings: null,

    init() {
      this._super(...arguments);

      this.buildUpdateList(Ember.get(this, 'primaryResource'));

      if (this.registerHook) {
        this.registerHook(this.saveMembers.bind(this), 'saveMembers');
      }
    },

    didReceiveAttrs() {
      this.buildUpdateList(Ember.get(this, 'primaryResource'));
    },

    actions: {
      cancel() {
        this.goBack();
      },

      addMember()
      /* kind */
      {
        let out = {
          principalId: null,
          bindings: []
        };
        Ember.get(this, 'memberArray').pushObject(out);
      },

      removeMember(obj) {
        Ember.setProperties(obj, {
          pendingDelete: true,
          bindings: []
        });
      }

    },
    defaultRoles: Ember.computed('roles.[]', 'type', function () {
      return Ember.get(this, 'roles').filterBy(`${Ember.get(this, 'type')}CreatorDefault`);
    }),
    filteredRoles: Ember.computed('roles.[]', 'type', function () {
      let pt = Ember.get(this, 'type');
      return Ember.get(this, 'roles').filterBy('hidden', false).filter(r => Ember.get(r, 'context') === pt || Ember.get(r, 'context') === '' || !Ember.get(r, 'context')).sortBy('displayName');
    }),
    filteredUsers: Ember.computed('users.@each.{id,state}', function () {
      return Ember.get(this, 'users').sortBy('displayName');
    }),

    buildUpdateList(resource) {
      let bindings = Ember.set(this, 'currentBindings', Ember.get(resource, 'roleTemplateBindings'));

      if (Ember.get(bindings, 'length') >= 1) {
        // editing
        let mOut = [];
        Ember.set(this, 'memberArray', bindings.filter(b => !Ember.get(b, 'serviceAccount')).forEach(b => {
          let match = mOut.findBy('principalId', Ember.get(b, 'principalIdReference'));

          if (match) {
            match.bindings.push(Ember.get(b, 'roleTemplateId'));
            match.preEditBindings.push({
              roleTemplateId: Ember.get(b, 'roleTemplateId'),
              id: Ember.get(b, 'id')
            });
          } else {
            match = {
              principalId: Ember.get(b, 'principalIdReference'),
              bindings: [Ember.get(b, 'roleTemplateId')],
              preEditBindings: [{
                roleTemplateId: Ember.get(b, 'roleTemplateId'),
                id: Ember.get(b, 'id')
              }],
              isCustom: Ember.get(b, 'isCustom')
            };
            mOut.push(match);
          }
        }));
        Ember.set(this, 'memberArray', mOut);
      } else {
        Ember.set(this, 'memberArray', []);
      }
    },

    createRole(inConfig = {}) {
      let config = (0, _jquery.default)().extend(true, {}, Ember.get(this, 'memberConfig'), inConfig);
      let type = Ember.get(this, 'primaryResource.type'); // set(config, 'subjectKind', 'User');

      Ember.set(config, `${type}Id`, Ember.get(this, `primaryResource.id`));
      return Ember.get(this, 'globalStore').createRecord(config);
    },

    saveMembers() {
      const memberArray = Ember.get(this, 'memberArray');
      const currentBindings = Ember.get(this, 'currentBindings');
      const add = [];
      const remove = [];
      const update = [];
      memberArray.forEach(member => {
        let bindings = Ember.get(member, 'bindings');
        let preEditBindings = Ember.get(member, 'preEditBindings') || [];

        if (bindings.length > 0) {
          // update
          if (bindings.length === 1 && Ember.get(member, 'preEditBindings.length') === 1) {
            let toUpdate = Ember.get(preEditBindings, 'firstObject');
            let neuRT = Ember.get(bindings, 'firstObject');
            let match = Ember.get(this, 'currentBindings').findBy('id', Ember.get(toUpdate, 'id'));

            if (match && Ember.get(match, 'roleTemplateId') !== neuRT) {
              Ember.set(match, 'roleTemplateId', neuRT);
              update.push(match);
            }
          } else {
            // bindings to add
            bindings.forEach(memberBinding => {
              if (!preEditBindings.findBy('roleTemplateId', memberBinding)) {
                if (Ember.get(member, 'principalId')) {
                  let principalId = Ember.get(member, 'principalId');
                  let type = Ember.get(member, 'memberType');

                  if (type === 'group') {
                    add.push(this.createRole({
                      groupPrincipalId: principalId,
                      roleTemplateId: memberBinding,
                      subjectKind: 'Group'
                    }));
                  } else {
                    add.push(this.createRole({
                      userPrincipalId: principalId,
                      roleTemplateId: memberBinding,
                      subjectKind: 'User'
                    }));
                  }
                }
              }
            });
            preEditBindings.forEach(peBinding => {
              if (!bindings.includes(Ember.get(peBinding, 'roleTemplateId'))) {
                remove.push(currentBindings.findBy('id', Ember.get(peBinding, 'id')));
              }
            });
          }
        } else {
          // remove
          preEditBindings.forEach(peBinding => {
            remove.push(currentBindings.findBy('id', Ember.get(peBinding, 'id')));
          });
        }
      });

      if (Ember.get(this, 'isNew') || this.useCustomizedOwner() || this.hasOwner()) {
        return Ember.RSVP.all(add.map(x => x.save())).then(() => {
          return Ember.RSVP.all(update.map(x => x.save())).then(() => {
            return Ember.RSVP.all(remove.map(x => x.delete())).then(() => {
              if (this.isDestroyed || this.isDestroying) {
                return;
              }

              return Ember.get(this, 'primaryResource');
            });
          });
        });
      } else {
        Ember.set(this, 'errors', [Ember.get(this, 'intl').t('formMembers.members.errors.ownerReq')]);
        return Ember.RSVP.reject();
      }
    },

    useCustomizedOwner() {
      const roles = Ember.get(this, 'roles') || [];
      const context = Ember.get(this, 'primaryResource.type');
      const ownerRole = roles.findBy('id', `${context}-owner`);
      const defaultRoles = roles.filterBy(`${context}CreatorDefault`, true);

      if (ownerRole && !Ember.get(ownerRole, 'locked') && Ember.get(ownerRole, `${context}CreatorDefault`) && Ember.get(defaultRoles, 'length') === 1) {
        return false;
      } else {
        return true;
      }
    },

    hasOwner() {
      let memberArray = Ember.get(this, 'memberArray');
      let matches = [];
      let okay = true; // find the matching bindings in the memebrsArray.bindings property, these will be the active bindings. when i binding is removed its also dropped out fo this array

      matches = memberArray.filter(member => {
        return Ember.get(member, 'bindings').includes(`${Ember.get(this, 'primaryResource.type')}-owner`);
      });

      if (matches.length <= 0) {
        okay = false;
      }

      return okay;
    },

    willDestroy() {
      Ember.set(this, 'memberArray', []);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});