define("nodes/components/driver-harvester/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-harvester/template", "ui/utils/debounce"], function (_exports, _nodeDriver, _template, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRIVER = 'harvester';
  const CONFIG = 'harvesterConfig';
  const SYSTEM_NAMESPACES = ['cattle-dashboards', 'cattle-global-data', 'cattle-system', 'gatekeeper-system', 'ingress-nginx', 'kube-node-lease', 'kube-public', 'kube-system', 'linkerd', 'rio-system', 'security-scan', 'tekton-pipelines'];

  var _default = Ember.Component.extend(_nodeDriver.default, {
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    driverName: DRIVER,
    model: {},
    currentCluster: null,
    clusters: [],
    clusterContent: [],
    imageContent: [],
    networkContent: [],
    namespaceContent: [],
    networkDataContent: [],
    userDataContent: [],
    controller: null,
    signal: '',
    isImportMode: true,
    config: Ember.computed.alias(`model.${CONFIG}`),

    init() {
      this._super(...arguments);

      const controller = new AbortController();
      Ember.set(this, 'controller', controller);
      this.fetchResource();
    },

    actions: {
      async finishAndSelectCloudCredential(credential) {
        await this.globalStore.findAll('cloudcredential', {
          forceReload: true
        });
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      },

      updateYaml(type, value) {
        Ember.set(this, `config.${type}`, value);
      }

    },
    clearData: Ember.observer('currentCredential.id', function () {
      Ember.set(this, 'config.imageName', '');
      Ember.set(this, 'config.networkName', '');
      Ember.set(this, 'config.vmNamespace', '');
    }),
    fetchResource: (0, _debounce.throttledObserver)('currentCredential.id', 'currentCredential.harvestercredentialConfig.clusterId', async function () {
      const clusterId = Ember.get(this, 'currentCredential') && Ember.get(this, 'currentCredential').harvestercredentialConfig && Ember.get(this, 'currentCredential').harvestercredentialConfig.clusterId;
      const url = clusterId === 'local' ? '' : `/k8s/clusters/${clusterId}`;

      if (!clusterId) {
        return;
      }

      let controller = Ember.get(this, 'controller');
      let signal = Ember.get(this, 'signal');
      signal = controller.signal;
      Ember.set(this, 'signal', signal);
      Ember.get(this, 'globalStore').rawRequest({
        url: `${url}/v1/harvesterhci.io.virtualmachineimages`
      }).then(resp => {
        const data = resp.body.data || [];
        const arr = data.filter(O => {
          return !O.spec.url.endsWith('.iso') && this.isReady.call(O);
        }).map(O => {
          const value = O.id;
          const label = `${O.spec.displayName} (${value})`;
          return {
            label,
            value
          };
        });
        Ember.set(this, 'imageContent', arr);
      }).catch(err => {
        Ember.set(this, 'imageContent', []);
        const message = err.statusText || err.message;
        Ember.get(this, 'growl').fromError('Error request Image API', message);
      });
      Ember.get(this, 'globalStore').rawRequest({
        url: `${url}/v1/k8s.cni.cncf.io.networkattachmentdefinition`
      }).then(resp => {
        const data = resp.body.data || [];
        const arr = data.map(O => {
          let id = '';

          try {
            const config = JSON.parse(O.spec.config);
            id = config.vlan;
          } catch (err) {
            Ember.get(this, 'growl').fromError('Error parse network config', err);
          }

          const value = O.id;
          const label = `${value} (vlanId=${id})`;
          return {
            label,
            value
          };
        });
        Ember.set(this, 'networkContent', arr);
      }).catch(err => {
        Ember.set(this, 'networkContent', []);
        const message = err.statusText || err.message;
        Ember.get(this, 'growl').fromError('Error request Network API', message);
      });
      Ember.get(this, 'globalStore').rawRequest({
        url: `${url}/v1/namespace`
      }).then(resp => {
        const data = resp.body.data || [];
        const arr = data.filter(O => {
          return !this.isSystemNamespace(O);
        }).map(O => {
          const value = O.id;
          const label = O.id;
          return {
            label,
            value
          };
        });
        Ember.set(this, 'namespaceContent', arr);
      }).catch(err => {
        Ember.set(this, 'namespaceContent', []);
        const message = err.statusText || err.message;
        Ember.get(this, 'growl').fromError('Error request Namespace API', message);
      });
      Ember.get(this, 'globalStore').rawRequest({
        url: `${url}/v1/configmap`
      }).then(resp => {
        const data = resp.body.data || [];
        const networkDataContent = [];
        const userDataContent = [];
        data.map(O => {
          const cloudTemplate = O.metadata && O.metadata.labels && O.metadata.labels['harvesterhci.io/cloud-init-template'];
          const value = O.data && O.data.cloudInit;
          const label = O.metadata.name;

          if (cloudTemplate === 'user') {
            userDataContent.push({
              label,
              value
            });
          } else if (cloudTemplate === 'network') {
            networkDataContent.push({
              label,
              value
            });
          }
        });
        Ember.set(this, 'userDataContent', userDataContent);
        Ember.set(this, 'networkDataContent', networkDataContent);
      }).catch(err => {
        Ember.set(this, 'userDataContent', []);
        Ember.set(this, 'networkDataContent', []);
        const message = err.statusText || err.message;
        Ember.get(this, 'growl').fromError('Error request cloudConfig API', message);
      });
      controller.abort();
    }),
    harvestercredentialConfig: Ember.computed('cloudCredentials.@each.harvestercredentialConfig', function () {
      return (Ember.get(this, 'cloudCredentials') || []).mapBy('harvestercredentialConfig');
    }),
    currentCredential: Ember.computed('cloudCredentials', 'harvestercredentialConfig.[]', 'model.cloudCredentialId', function () {
      return (Ember.get(this, 'cloudCredentials') || []).find(C => C.id === Ember.get(this, 'model.cloudCredentialId'));
    }),
    isImported: Ember.computed('currentCredential.harvestercredentialConfig.clusterType', function () {
      if (Ember.get(this, 'currentCredential') && Ember.get(this, 'currentCredential').harvestercredentialConfig) {
        return Ember.get(this, 'currentCredential').harvestercredentialConfig.clusterType === 'imported';
      }

      return false;
    }),

    isSystemNamespace(namespace) {
      if (namespace.metadata && namespace.metadata.annotations && namespace.metadata.annotations['management.cattle.io/system-namespace'] === 'true') {
        return true;
      }

      if (SYSTEM_NAMESPACES.includes(namespace.metadata.name)) {
        return true;
      }

      if (namespace.metadata && namespace.metadata.name && namespace.metadata.name.endsWith('-system')) {
        return true;
      }

      return false;
    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        cpuCount: 2,
        memorySize: 4,
        diskSize: 40,
        diskBus: 'virtio',
        imageName: '',
        sshUser: '',
        networkName: '',
        networkData: '',
        vmNamespace: '',
        userData: ''
      });
      Ember.set(this, `model.${CONFIG}`, config);
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (!Ember.get(this, 'config.vmNamespace')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.namespace.label')
        }));
      }

      if (!Ember.get(this, 'config.diskBus')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.diskBus.label')
        }));
      }

      if (!Ember.get(this, 'config.imageName')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.imageName.label')
        }));
      }

      if (!Ember.get(this, 'config.networkName')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.networkName.label')
        }));
      }

      if (!Ember.get(this, 'config.sshUser')) {
        errors.push(this.intl.t('generic.required', {
          key: this.intl.t('nodeDriver.harvester.sshUser.label')
        }));
      } // Set the array of errors for display,
      // and return true if saving should continue.


      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    },

    isReady() {
      var _this$status;

      function getStatusConditionOfType(type, defaultValue = []) {
        const conditions = Array.isArray(Ember.get(this, 'status.conditions')) ? this.status.conditions : defaultValue;
        return conditions.find(cond => cond.type === type);
      }

      const initialized = getStatusConditionOfType.call(this, 'Initialized');
      const imported = getStatusConditionOfType.call(this, 'Imported');
      const isCompleted = ((_this$status = this.status) === null || _this$status === void 0 ? void 0 : _this$status.progress) === 100;

      if ([initialized === null || initialized === void 0 ? void 0 : initialized.status, imported === null || imported === void 0 ? void 0 : imported.status].includes('False')) {
        return false;
      } else {
        return isCompleted && true;
      }
    }

  });

  _exports.default = _default;
});