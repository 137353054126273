define("shared/mixins/cluster-driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    configField: '<override me>',
    mode: null,
    save: null,
    // Action to save
    close: null,
    // Action on complete
    saving: false,
    saved: false,
    globalStore: Ember.inject.service(),
    cluster: Ember.computed.alias('model.cluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    errors: null,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'config', Ember.computed('configField', `primaryResource.${this.configField}`, this._getConfigField));
    },

    actions: {
      errorHandler(err, shouldClearPreviousErrors = false) {
        let {
          errors
        } = this;

        if (shouldClearPreviousErrors) {
          errors = Ember.set(this, 'errors', []);
        }

        if (errors) {
          if (Ember.isArray(err)) {
            errors.pushObjects(err);
          } else {
            errors.pushObject(err);
          }
        } else {
          errors = [err];
        }

        Ember.set(this, 'errors', errors);
      },

      driverSave(cb) {
        cb = cb || function () {};

        Ember.RSVP.resolve(this.willSave()).then(ok => {
          if (!ok) {
            // Validation or something else said not to save
            cb(false);
            return;
          }

          if (this.save) {
            this.save(ok => {
              if (ok) {
                this.doneSaving().finally(() => {
                  cb(ok);
                });
              } else {
                cb(ok);
              }
            });
          }
        });
      },

      setLabels(labels) {
        Ember.set(this, 'labels', labels);
        var out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        this.set('labels', out);
      },

      registerHook() {
        const args = [].slice.call(arguments);
        args.unshift('registerHook');
        this.sendAction.apply(this, args);
      },

      close() {
        if (this.close) {
          this.close(this.saved);
        }
      }

    },

    willSave() {
      const {
        cluster,
        configField: field
      } = this;

      if (Ember.typeOf(cluster.clearProvidersExcept) === 'function') {
        cluster.clearProvidersExcept(field);
      }

      Ember.set(this, 'errors', null);
      return this.validate();
    },

    validate() {
      const model = Ember.get(this, 'cluster');
      const errors = model.validationErrors(['appliedSpec']);
      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    doneSaving() {
      return Ember.RSVP.resolve();
    },

    _getConfigField() {
      return Ember.get(this, `cluster.${Ember.get(this, 'configField')}`);
    }

  });

  _exports.default = _default;
});